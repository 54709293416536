var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"container"},[_c('b-form-input',{staticClass:"input col-12",attrs:{"placeholder":"Søg"},model:{value:(_vm.globalfilter),callback:function ($$v) {_vm.globalfilter=$$v},expression:"globalfilter"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('BaseTable',{attrs:{"items":_vm.users,"fields":_vm.fields,"isBusy":_vm.isBusy},scopedSlots:_vm._u([{key:"cell(fullName)",fn:function(data){return [_c('router-link',{attrs:{"to":{ name: 'User', params: { id: data.item.id } }}},[_vm._v(" "+_vm._s(data.item.firstName)+" "+_vm._s(data.item.lastName)+" ")])]}},{key:"cell(agreements)",fn:function(data){return [_vm._l((data.item.policies),function(item){return [_c('router-link',{attrs:{"to":{
								name: 'CompanyEmployeeInfo',
								params: {
									companyId: item.companyId,
									employeeId: data.item.id,
									policyId: item.policyId,
								},
							}}},[_vm._v(" "+_vm._s(_vm.company(item.companyId))+" ")])]})]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }